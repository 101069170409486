<template>
  <div>
    <div id="app">
      <div v-if="this.getIsSidenavVisible" class="full-screen-overlay" @click="closeSidenav"></div>

      <app-skiplinks></app-skiplinks>
      <app-header></app-header>
      <app-titleBar></app-titleBar>
      <app-modal-comparison></app-modal-comparison>
      <app-sidenav v-if="this.getIsSidenavVisible" />
      <main role="main">
        <router-view />
      </main>
      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
import HeaderDsfr from "./components/Header.vue";
import FooterDsfr from "./components/Footer.vue";
import SkipLinksDsfr from "./components/SkipLinks.vue";
import Sidenav from "./components/Sidenav.vue";
import {mapGetters, mapMutations} from "vuex";
import TitleBar from "./components/TitleBar.vue";
import ModalComparison from "./components/modals/ModalComparison.vue";

export default {
  components: {
    "app-sidenav": Sidenav,
    "app-header": HeaderDsfr,
    "app-titleBar": TitleBar,
    "app-footer": FooterDsfr,
    "app-skiplinks": SkipLinksDsfr,
    "app-modal-comparison": ModalComparison,
  },
  computed: {
    ...mapGetters([
        'getIsSidenavVisible'
    ]),
  },
  watch: {
    $route(to) {
      document.title =
        to.meta.title ||
        "L’Observatoire des données des sites Internet de l’État";
    },
  },
  methods: {
    ...mapMutations(['SET_IS_SIDENAV_VISIBLE']),
    closeSidenav() {
      this.SET_IS_SIDENAV_VISIBLE(false);
    },
  }
};
</script>
<style>
@import 'styles/global.scss';
</style>

<template>
  <div class="fr-p-3w">
      <div class="fr-col-11 fr-col-lg">
        <multi-line-chart :serie-obj="serieObj.serie_values"></multi-line-chart>
      </div>
  </div>
</template>

<script>
import MultiLineChart from "../componentsDsfr/MultiLineChart.vue";

export default {
  name: 'LineSeriesChart',
  components: {
    "multi-line-chart":MultiLineChart
  },
  props: {
    serieObj: Object
  }
}
</script>

<template>
  <dialog
      aria-labelledby="fr-modal-title-modal-comparison"
      role="dialog"
      id="fr-modal-comparison"
      class="fr-modal"
  >
    <div class="fr-container fr-container--fluid fr-container-md" style="position: absolute;" :style="styleDiv">
      <div class="fr-grid-row">
        <div class="fr-col-12 fr-col-lg-6">
          <div class="fr-modal__body fr-col-8" ref="compareModal">
            <div class="fr-modal__header">
              <fieldset class="fr-segmented fr-segmented--sm fr-mb-1w">
                <div class="fr-segmented__elements">
                  <div class="fr-segmented__element">
                    <input value="1"
                           type="radio"
                           id="segmented-2230-1"
                           name="segmented-2230"
                           @click="handleSelectedSegment(true)">
                    <label class="fr-label" for="segmented-2230-1">
                      Dimensions
                    </label>
                  </div>
                  <div class="fr-segmented__element">
                    <input value="2"
                           checked
                           type="radio"
                           id="segmented-2230-2"
                           name="segmented-2230"
                           @click="handleSelectedSegment(false)">
                    <label class="fr-label" for="segmented-2230-2">
                      Période
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="fr-modal__content">

              <div v-if="this.getIsDimensionsSegmentSelected" class="fr-select-group">
                <fieldset class="modal-compare__fieldset fr-px-0 fr-mx-0">
                  <label class="fr-label" for="select">
                    {{ this.getDimensionName }}
                  </label>
                  <select
                      v-if="this.getDimensionName === 'Ministère de tutelle' || this.getDimensionName === ''"
                      v-model="selectionComparativeMinistry"
                      class="fr-select"
                      id="select"
                      name="select"
                      title="Sélectionner un ministère"
                  >
                    <option value="undefined" selected disabled hidden>Sélectionner un ministère</option>
                    <option v-for="t in comparativeMinistries" :value="t['value']" :key="t['value']">
                      {{ t["label"] }}
                    </option>
                  </select>
                  <select
                      v-if="this.getDimensionName === 'Type de site'"
                      v-model="selectionComparativeTypeSite"
                      class="fr-select"
                      id="select"
                      name="select"
                      title="Sélectionner un type de site"
                  >
                    <option value="undefined" selected disabled hidden>Sélectionner un type de site</option>
                    <option v-for="t in comparativeTypeSites" :value="t['value']" :key="t['value']">
                      {{ t["label"] }}
                    </option>
                  </select>
                  <select
                      v-if="this.getDimensionName === 'Site'"
                      v-model="selectionComparativeSite"
                      class="fr-select"
                      id="select"
                      name="select"
                      title="Sélectionner un site"
                  >
                    <option value="undefined" selected disabled hidden>Sélectionner un site</option>
                    <option v-for="t in comparativeSites" :value="t['value']" :key="t['value']">
                      {{ t["label"] }}
                    </option>
                  </select>
                </fieldset>
              </div>

              <div v-else>
                <fieldset class="fr-fieldset" id="label-hint"
                          aria-labelledby="text-label-hint-legend text-input-start-date-messages">
                  <div class="fr-fieldset__element">
                    <div class="fr-input-group">
                      <label class="fr-label" for="text-input-start-date">
                        Début
                      </label>
                      <input class="fr-input fr-input--error" aria-describedby="text-input-date-messages"
                             id="text-input-start-date" type="date" v-model="comparativeStartDatePicker"
                             @change="changeComparativeStartDate"
                             title="Afficher le sélecteur de dates"/>
                      <p v-if="!isNotRightComparativeStartDate && !isNotRightSelectedDate" id="text-input-info-filter"
                         class="fr-message--info fr-text--xs">
                        La période que vous comparez est identique à celle des filtres
                      </p>
                      <p v-if="isNotRightComparativeStartDate" id="text-input-error-previous-date"
                         class="fr-message--error fr-text--xs">
                        Il faut selectionner une date antérieur à {{ validComparedStartDate }} pour faire une
                        comparaison
                      </p>
                      <p v-if="isNotRightSelectedDate" id="text-input-error-date" class="fr-message--error fr-text--xs">
                        La date séléctionnée n'est pas valide
                      </p>
                      <div class="fr-messages-group" id="text-input-start-date-messages" aria-live="assertive">
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              <button
                  id="fr-btn-valider"
                  class="fr-btn position-right"
                  title="Valider"
                  aria-controls="fr-modal-comparison"
                  @click="sendTag"
              >Valider
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {endPoint, getDataApi} from "../../services/api";
import {calculateEndDate, convertDate, dateDelta} from "../../utils";

export default {
  name: "ModalComparison",
  data() {
    return {
      validComparedStartDate: "",
      isNotRightComparativeStartDate: false,
      isNotRightSelectedDate: false,
      widthModal: ''
    }
  },
  computed: {
    ...mapGetters([
      'getCompareButtonPosition',
      'getCompareModalWidth',
      'getDimensionName',
      'getSelectedMinistry',
      'getSelectedTypeSite',
      'getSelectedSite',
      'getIsDimensionsSegmentSelected',
      'getSelectionComparativeMinistry',
      'getSelectionComparativeTypeSite',
      'getSelectionComparativeSite',
      'getComparativeMinistries',
      'getComparativeTypeSites',
      'getComparativeSites',
      'getComparativeStartDatePicker',
      'getPeriod',
      'getSelectedSegment'
    ]),
    selectionComparativeMinistry: {
      get() {
        return this.getSelectionComparativeMinistry;
      },
      set(value) {
        this.setSelectionComparativeMinistry(value);
      }
    },
    selectionComparativeTypeSite: {
      get() {
        return this.getSelectionComparativeTypeSite;
      },
      set(value) {
        this.setSelectionComparativeTypeSite(value);
      }
    },
    selectionComparativeSite: {
      get() {
        return this.getSelectionComparativeSite;
      },
      set(value) {
        this.setSelectionComparativeSite(value);
      }
    },
    comparativeMinistries: {
      get() {
        return this.getComparativeMinistries;
      },
    },
    comparativeTypeSites: {
      get() {
        return this.getComparativeTypeSites;
      }
    },
    comparativeSites: {
      get() {
        return this.getComparativeSites;
      }
    },
    comparativeStartDatePicker: {
      get() {
        return this.getComparativeStartDatePicker;
      },
      set(value) {
        this.setComparativeStartDatePicker(value);
      }
    },
    styleDiv: {
      get() {
        return this.generateStyleDiv();
      },
      set(value) {
        this.setCompareButtonPosition(value);
      }
    }
  },
  methods: {
    ...mapActions([
      'setCompareModalWidth',
      'setIsDimensionsSegmentSelected',
      'setSelectionComparativeMinistry',
      'setSelectionComparativeTypeSite',
      'setSelectionComparativeSite',
      'setComparativeStartDatePicker',
      'setComparativeTag',
      'setCompareButtonPosition',
      'setIsComparativeTag',
      'setComparativeParams',
      'setDimensionName',
      'setSelectedSegment'
    ]),
    handleSelectedSegment(isDimensions) {
      this.setIsDimensionsSegmentSelected(isDimensions);
    },
    changeComparativeStartDate() {
      this.isNotRightComparativeStartDate = false;
      let comparativeEndDate = calculateEndDate(this.getComparativeStartDatePicker, this.getPeriod);
      if (new Date(convertDate(this.validComparedStartDate)) <= new Date(this.getComparativeStartDatePicker)) {
        this.isNotRightComparativeStartDate = true;
      }
      this.isNotRightSelectedDate = new Date("2023-10-02") > new Date(comparativeEndDate);
    },
    generateComparativeParams(dimension, comparisonLabel) {
      let comparativeParams = {
        table_name: "ft_visite_sources",
        study_period: [
          {
            date_start: "2024-09-01",
            date_end: "2024-09-29"
          }
        ],
        filter_by: []
      }

      if (this.getDimensionName.includes("Ministère de tutelle")) {
        comparativeParams.filter_by.push({
          field: "id_ministere",
          values: [dimension, comparisonLabel]
        });
      }
      if (this.getDimensionName.includes("Type de site")) {
        comparativeParams.filter_by.push({
          field: "id_type_site",
          values: [dimension, comparisonLabel]
        });
      }
      if (this.getDimensionName.includes("Site")) {
        comparativeParams.filter_by.push({
          field: "id_site",
          values: [dimension, comparisonLabel]
        });
      }
      if (dimension.includes("period")) {
        comparativeParams.study_period.push({
          date_start: comparisonLabel
        });
        delete comparativeParams.filter_by;
      }
      return comparativeParams;
    },
    sendTag() {
      let comparisonLabel;
      let comparativeParams;
      if (this.getIsDimensionsSegmentSelected && !this.isFilterUndefined()) {
        let dimension;

        if (this.getSelectionComparativeMinistry !== undefined) {
          dimension = this.getSelectedMinistry;
          comparisonLabel = this.getSelectionComparativeMinistry;
        }
        if (this.getSelectionComparativeTypeSite !== undefined) {
          dimension = this.getSelectedTypeSite;
          comparisonLabel = this.getSelectionComparativeTypeSite;
        }
        if (this.getSelectionComparativeSite !== undefined) {
          dimension = this.getSelectedSite;
          comparisonLabel = this.getSelectionComparativeSite;
        }
        comparativeParams = this.generateComparativeParams(dimension, comparisonLabel);
        this.setComparativeTag({label: comparisonLabel, role: "comparativeDimensions"});
        this.setComparativeParams(comparativeParams);
        this.setIsComparativeTag(true);
      } else {
        if (this.getComparativeStartDatePicker !== undefined) {
          comparativeParams = this.generateComparativeParams("period", this.getComparativeStartDatePicker);
          let comparativeEndDate = calculateEndDate(this.getComparativeStartDatePicker, this.getPeriod);
          comparisonLabel = convertDate(this.getComparativeStartDatePicker).replaceAll("-", "/") + " - "
              + convertDate(comparativeEndDate).replaceAll("-", "/");
          if (!this.isNotRightComparativeStartDate && !this.isNotRightSelectedDate) {
            this.setComparativeTag({label: comparisonLabel, role: "comparativePeriod"});
            this.setComparativeParams(comparativeParams);
            this.setIsComparativeTag(true);
          }
        }
      }
    },
    isFilterUndefined() {
      return this.getSelectedMinistry === undefined && this.getSelectedTypeSite === undefined && this.getSelectedSite === undefined;
    },
    calculateWidthModal() {
      const compareModal = this.$refs.compareModal.getBoundingClientRect();
      this.setCompareModalWidth(compareModal.width);
    },
    generateStyleDiv() {
      let position;
      if (this.getCompareButtonPosition !== undefined) {
        let calculateLeft = this.getCompareButtonPosition.left - this.getCompareModalWidth;
        position = {
          left: calculateLeft + "px",
          top: this.getCompareButtonPosition.top + "px"
        }
      }
      return position;
    },
    myEventHandler() {
      this.calculateWidthModal();
    }
  },
  created() {
    window.addEventListener("resize-modal", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize-modal", this.myEventHandler);
  },
  mounted() {
    getDataApi(endPoint, "dim_ministere");
    this.validComparedStartDate = convertDate(dateDelta(this.getPeriod));
    this.calculateWidthModal();
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/components/modals/modalComparison.scss";
</style>
<template>
  <div>
    <div v-if="!queryFinishSerie" class="fr-mt-1w" aria-live="polite">
      <div class="loading">
        <div class="loading__spinner"></div>
      </div>
    </div>

    <section>
      <div class="fr-card fr-card--no-icon fr-pt-3w" v-if="!querySuccess">
        <h3>Aucune donnée ne correspond à cette requête sur cette période</h3>
      </div>
    </section>

    <section>
      <h2 id="analyse" class="fr-pt-3w fr-mb-0" v-if="querySuccess">
        Analyse de l'engagement
      </h2>
    </section>
    <!-- TOP Sites -->
    <section>
      <div class="fr-container--fluid">
        <div class="fr-grid-row fr-grid-row--gutters">
          <div
              :class="{ 'display-none': !this.isDisplayedSites, 'fr-col-12 fr-col-lg-6 fr-col-xl-4': this.isDisplayedSites }">
            <GraphBoxGeneric :dataObj="topSitesObj" v-if="queryTopSitesSuccess"></GraphBoxGeneric>
          </div>
          <div
              :class="{ 'fr-col-12 fr-col-lg-6': !this.isDisplayedSites, 'fr-col-12 fr-col-lg-6 fr-col-xl-4': this.isDisplayedSites }">
            <GraphBoxGeneric :dataObj="visiteObj" v-if="querySuccess"></GraphBoxGeneric>
          </div>
          <div
              :class="{ 'fr-col-12 fr-col-lg-6': !this.isDisplayedSites, 'fr-col-12 fr-col-xl-4': this.isDisplayedSites }">
            <GraphBoxGeneric :dataObj="durationObj" v-if="querySuccess"></GraphBoxGeneric>
          </div>
        </div>
      </div>
    </section>

    <!-- TOP Pages -->
    <section>
      <div class="fr-container--fluid">
        <div class="fr-grid-row fr-grid-row--gutters">
          <div class="fr-col-12 fr-col-lg-6">
            <GraphBoxGeneric :dataObj="topPagesObj" v-if="queryTopPagesSuccess"></GraphBoxGeneric>
          </div>
          <div class="fr-col-12 fr-col-lg-6">
            <GraphBoxGeneric :dataObj="vueObj" v-if="querySuccess"></GraphBoxGeneric>
          </div>
        </div>
      </div>
    </section>

    <section>
      <h2 id="access-site" v-if="queryPiechartSuccess" class="fr-pt-5w fr-mb-0">
        Accès aux sites
      </h2>
    </section>
    <section>
      <div class="fr-container--fluid">
        <div class="fr-grid-row fr-grid-row--gutters">
          <div class="fr-col-12 fr-col-lg-6">
            <GraphBoxGeneric :dataObj="deviceObj" v-if="queryPiechartSuccess"></GraphBoxGeneric>
          </div>
          <div class="fr-col-12 fr-col-lg-6">
            <GraphBoxGeneric :dataObj="sourceObj" v-if="querySourcesSuccess"></GraphBoxGeneric>
          </div>
        </div>
      </div>
    </section>

    <!-- Type de site -->
    <section>
      <h2 id="type-site" v-if="isDisplayedSites && (isDisplayedMinistry || isDisplayedTypeSites)"
          class="fr-pt-5w fr-mb-0">
        Type de site
      </h2>
    </section>
    <section>
      <div class="fr-container--fluid">
        <div class="fr-grid-row fr-grid-row--gutters">
          <div :class="{
      'display-none': !isDisplayedSites || !isDisplayedMinistry,
      'fr-col-12': !isDisplayedTypeSites,
      'fr-col-12 fr-col-lg-6': isDisplayedSites && isDisplayedMinistry
    }">
            <GraphBoxGeneric :dataObj="ministereObj" v-if="queryBarChartSuccess"></GraphBoxGeneric>
          </div>
          <div :class="{
      'display-none': !isDisplayedSites || !isDisplayedTypeSites,
      'fr-col-12': !isDisplayedMinistry,
      'fr-col-12 fr-col-lg-6': isDisplayedSites && isDisplayedTypeSites
    }">
            <GraphBoxGeneric :dataObj="typeSiteObj" v-if="queryTypeSitesSuccess"></GraphBoxGeneric>
          </div>
        </div>
      </div>
    </section>

    <!-- Cartographie -->
    <section>
      <h2 id="geographic-origin" v-if="querySuccess" class="fr-pt-5w fr-mb-0">
        Provenance géographique
      </h2>
    </section>
    <section>
      <div class="fr-container--fluid">
        <div class="fr-grid-row fr-grid-row--gutters">
          <div class="fr-col-12" v-if="querySuccess">
            <MapsBox :query="this.params"></MapsBox>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import PieBox from "../boxes/PieBox.vue";
import BarSeriesChart from "./BarSeriesChart.vue";
import GraphBoxGeneric from "../boxes/GraphBoxGeneric.vue";
import MapsBox from "../boxes/MapsBox.vue";
import LineSeriesChart from "./LineSeriesChart.vue";
import {mapGetters} from "vuex";
import {endPoint, fetchDataChart} from "../../services/api";
import {getColors} from "../../utils";

export default {
  name: "VisitsChart",
  components: {
    LineSeriesChart,
    PieBox,
    BarSeriesChart,
    GraphBoxGeneric,
    MapsBox
  },
  data() {
    return {
      //series
      querySuccess: false,
      visiteObj: Object,
      vueObj: Object,
      durationObj: Object,
      //pie chart
      queryPiechartSuccess: false,
      querySourcesSuccess: false,
      deviceObj: Object,
      sourceObj: Object,
      //bar chart
      queryBarChartSuccess: false,
      ministereObj: Object,
      queryTypeSitesSuccess: false,
      typeSiteObj: Object,
      nb_sites_visits: 0,
      queryFinishSerie: false,
      //top sites
      topSitesObj: Object,
      queryTopSitesSuccess: false,
      //top pages
      topPagesObj: Object,
      queryTopPagesSuccess: false,

      isDisplayedSites: false,
      isDisplayedMinistry: false,
      isDisplayedTypeSites: false,

      fetchResponses: {
        topSites: {},
        visits: {},
        topPages: {},
        visitsByDevice: {},
        visitsBySource: {},
        visitsByMinistry: {},
        visitsByTypeSite: {},
        geographicOrigin: {}
      },
    };
  },
  computed: {
    ...mapGetters([
      'getParams',
      'getSelectedMinistry',
      'getSelectedTypeSite',
      'getSelectedSite',
      'getComparativeParams',
      'getTags',
      'getIsComparativeTag'
    ]),
    params: {
      get() {
        return this.getParams;
      }
    },
    comparativeParams: {
      get() {
        return this.getComparativeParams;
      }
    }
  },
  watch: {
    params: function () {
      this.fetchAndCreateCharts();
    },
    comparativeParams: function () {
      this.fetchAndCreateCharts();
    }
  },
  methods: {
    async fetchTopSites() {
      try {
        let params = this.comparativeParams ? this.comparativeParams : this.params;
        params.limit = 10;
        this.fetchResponses.topSites = await fetchDataChart(params, endPoint.topSitesPages);
      } catch (error) {
        console.error("Error in fetchTopSites", error);
      }
    },
    async fetchVisits() {
      try {
        let params = this.comparativeParams ? this.comparativeParams : this.params;
        this.fetchResponses.visits = await fetchDataChart(params, endPoint.visit);
      } catch (error) {
        console.error("Error in fetchVisits", error);
      }
    },
    async fetchTopPages() {
      try {
        let params = this.comparativeParams ? this.comparativeParams : this.params;
        let auxParams = JSON.parse(JSON.stringify(params));
        auxParams.limit = 10;
        auxParams.table_name = "ft_visite_pages";
        this.fetchResponses.topPages = await fetchDataChart(auxParams, endPoint.topSitesPages);
      } catch (error) {
        console.error("Error in fetchVisits", error);
      }
    },
    async fetchVisitsByDevice() {
      try {
        let params = this.comparativeParams ? this.comparativeParams : this.params;
        params.color = ['green-bourgeon', 'blue-ecume', 'purple-glycine-main-494', 'yellow-moutarde-main-679'];
        this.fetchResponses.visitsByDevice = await fetchDataChart(params, endPoint.visitsDevice);
      } catch (error) {
        console.error("Error in fetchVisits", error);
      }
    },
    async fetchVisitsBySource() {
      try {
        let params = this.comparativeParams ? this.comparativeParams : this.params;
        params.color = ['blue-ecume', 'green-archipel-925', 'brown-opera-moon-820', 'purple-glycine-main-494', 'yellow-moutarde-main-679', 'purple-glycine', 'green-bourgeon', 'warning-main-525', 'grey-850']
        this.fetchResponses.visitsBySource = await fetchDataChart(params, endPoint.visitsSource);
      } catch (error) {
        console.error("Error in fetchVisits", error);
      }
    },
    async fetchVisitsByMinistry() {
      try {
        let params = this.comparativeParams ? this.comparativeParams : this.params;
        this.fetchResponses.visitsByMinistry = await fetchDataChart(params, endPoint.visitsMinistry);
      } catch (error) {
        console.error("Error in fetchVisits", error);
      }
    },
    async fetchVisitsByTypeSite() {
      try {
        let params = this.comparativeParams ? this.comparativeParams : this.params;
        this.fetchResponses.visitsByTypeSite = await fetchDataChart(params, endPoint.visitsTypeSite);
      } catch (error) {
        console.error("Error in fetchVisits", error);
      }
    },
    updateColorSeries(response, value) {
      let series = response.series[value];
      series.serie_values.color = getColors();
      return series
    },
    createTopSites(response) {
      if (response !== undefined && this.getSelectedSite === undefined || this.getIsComparativeTag) {
        this.queryTopSitesSuccess = true;
        this.isDisplayedSites = true;
        this.topSitesObj = {
          titleBox: {
            title: response.series[0].title
          },
          graph: {
            component: BarSeriesChart,
            props: this.updateColorSeries(response,0),
            table: response.series[0].table
          },
          accordion: {
            title: "En savoir plus sur l'indicateur",
            description: response.series[0].description,
            id: "myaccordion7",
            open: false
          }
        };
      } else {
        this.queryTopSitesSuccess = false;
        this.isDisplayedSites = false;
      }
    },
    createVisits(response) {
      if (response !== undefined) {
        this.querySuccess = true;
        this.visiteObj = {
          titleBox: {
            title: response.series[0].nameX
          },
          graph: {
            component: LineSeriesChart,
            props: this.updateColorSeries(response, 0),
            table: response.series[0].table
          },
          accordion: {
            title: "En savoir plus sur l'indicateur",
            description: response.series[0].description,
            id: "myaccordion1",
            open: false
          },
        };
      } else {
        this.querySuccess = false;
      }
      this.queryFinishSerie = true
    },
    createVisitDurations(response) {
      if (response !== undefined) {
        this.querySuccess = true;
        this.durationObj = {
          titleBox: {
            title: response.series[2].nameX
          },
          graph: {
            component: LineSeriesChart,
            props: this.updateColorSeries(response, 2),
            table: response.series[2].table
          },
          accordion: {
            title: "En savoir plus sur l'indicateur",
            description: response.series[2].description,
            id: "myaccordion3",
            open: false
          }
        };
      } else {
        this.querySuccess = false;
      }
      this.queryFinishSerie = true
    },
    createTopPages(response) {
      if (response !== undefined) {
        this.queryTopPagesSuccess = true;
        this.topPagesObj = {
          titleBox: {
            title: response.series[0].title
          },
          graph: {
            component: BarSeriesChart,
            props: this.updateColorSeries(response,0),
            table: response.series[0].table
          },
          accordion: {
            title: "En savoir plus sur l'indicateur",
            description: response.series[0].description,
            id: "myaccordion8",
            open: false
          }
        };
      } else {
        this.queryTopPagesSuccess = false;
      }
    },
    createPageViews(response) {
      if (response !== undefined) {
        this.querySuccess = true;
        this.vueObj = {
          titleBox: {
            title: response.series[1].nameX
          },
          graph: {
            component: LineSeriesChart,
            props: this.updateColorSeries(response, 1),
            table: response.series[1].table
          },
          accordion: {
            title: "En savoir plus sur l'indicateur",
            description: response.series[1].description,
            id: "myaccordion2",
            open: false
          }
        };
      } else {
        this.querySuccess = false;
      }
      this.queryFinishSerie = true
    },
    createVisitsByDevice(response) {
      if (response !== undefined) {
        this.queryPiechartSuccess = true;
        this.deviceObj = {
          titleBox: {
            title: response.series[0].title
          },
          graph: {
            component: PieBox,
            props: response.series[0],
            table: response.series[0].table
          },
          accordion: {
            title: "En savoir plus sur l'indicateur",
            description: response.series[0].description,
            id: "myaccordion4",
            open: false
          }
        };
      } else {
        this.queryPiechartSuccess = false;
      }
    },
    createVisitsBySource(response) {
      if (response !== undefined) {
        this.querySourcesSuccess = true;
        this.sourceObj = {
          titleBox: {
            title: response.series[0].title
          },
          graph: {
            component: PieBox,
            props: response.series[0],
            table: response.series[0].table
          },
          accordion: {
            title: "En savoir plus sur l'indicateur",
            description: response.series[0].description,
            id: "myaccordion5",
            open: false
          },
        };
      } else {
        this.querySourcesSuccess = false;
      }
    },
    createVisitsByMinistry(response) {
      if (response !== undefined && this.getSelectedSite === undefined && this.getSelectedMinistry === undefined || this.getIsComparativeTag) {
        this.queryBarChartSuccess = true;
        this.isDisplayedMinistry = true;
        this.ministereObj = {
          titleBox: {
            title: response.series[0].title
          },
          graph: {
            component: BarSeriesChart,
            props: this.updateColorSeries(response,0),
            table: response.series[0].table
          },
          accordion: {
            title: "En savoir plus sur l'indicateur",
            description: response.series[0].description,
            id: "myaccordion6",
            open: false
          }
        };
      } else {
        this.queryBarChartSuccess = false;
        this.isDisplayedMinistry = false;
      }
    },
    createVisitsByTypeSite(response) {
      if (response !== undefined && this.getSelectedSite === undefined && this.getSelectedTypeSite === undefined || this.getIsComparativeTag) {
        this.queryTypeSitesSuccess = true;
        this.isDisplayedTypeSites = true;
        this.typeSiteObj = {
          titleBox: {
            title: response.series[0].title
          },
          graph: {
            component: BarSeriesChart,
            props: this.updateColorSeries(response,0),
            table: response.series[0].table
          },
          accordion: {
            title: "En savoir plus sur l'indicateur",
            description: response.series[0].description,
            id: "myaccordion",
            open: false
          }
        };
      } else {
        this.queryTypeSitesSuccess = false;
        this.isDisplayedTypeSites = false;
      }
    },
    getData() {
      return Promise.all([
        this.fetchTopSites(),
        this.fetchVisits(),
        this.fetchTopPages(),
        this.fetchVisitsByDevice(),
        this.fetchVisitsBySource(),
        this.fetchVisitsByMinistry(),
        this.fetchVisitsByTypeSite()
      ])
    },

    createCharts() {
      this.createTopSites(this.fetchResponses.topSites);
      this.createVisits(this.fetchResponses.visits);
      this.createVisitDurations(this.fetchResponses.visits);
      this.createTopPages(this.fetchResponses.topPages);
      this.createPageViews(this.fetchResponses.visits);
      this.createVisitsByDevice(this.fetchResponses.visitsByDevice);
      this.createVisitsBySource(this.fetchResponses.visitsBySource);
      this.createVisitsByMinistry(this.fetchResponses.visitsByMinistry);
      this.createVisitsByTypeSite(this.fetchResponses.visitsByTypeSite);
    },
    async fetchAndCreateCharts() {
      this.getData().then(() => {
        this.createCharts();
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    },
  },
  mounted() {
  }
};
</script>

<style scoped lang="scss">
@import '../../styles/components/charts/visitsChart.scss';
</style>

<template>
  <div class="fr-px-4w fr-pt-3w">
    <pie-chart  v-for="element in serieObj.serie_values.y" :x="JSON.stringify(serieObj.serie_values.x[0])" :y="JSON.stringify(element)"
      :name="JSON.stringify(serieObj.serie_values.legend_names)" :date="serieObj.update_date" :fill="true" :color=JSON.stringify(serieObj.color)>
    </pie-chart>
  </div>
</template>

<script>

import PieChart from "../componentsDsfr/PieChart.vue";
export default {
  components: {
    PieChart,
  },
  name: "PieBox",
  props: {
    serieObj: Object,
  }
};
</script>

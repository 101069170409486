<template>
  <div class="fr-pt-3w">
    <div class="fr-px-1w fr-pt-3w">
      <div class="fr-grid-row">
        <div class="fr-col-12 fr-col-9">
          <bar-chart
              :x=serieObj.serie_values.x
              :y=serieObj.serie_values.y
              :name=JSON.stringify(serieObj.serie_values.name)
              :date=serieObj.update_date
              :horizontal=true
              :color=serieObj.serie_values.color
          >
          </bar-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import BarChart from '../componentsDsfr/BarChart.vue'

export default {
  name: 'BarChartGrid',
  components: {
    BarChart
  },
  props: {
    serieObj: Object
  },
  mounted() {
  }
}
</script>

<template>
    <div class="fr-table fr-table--bordered fr-table--layout-fixed fr-table--no-caption fr-p-1w fr-tab-data">
        <table>
            <caption>{{ captionTitle }}</caption>
            <thead>
                <tr v-if="table.headers != 'undefined'">
                    <th class="myth" v-for="(header, headIndex) in table.headers" :key="headIndex" :colspan=header.colspan > {{header.label}} </th>
                </tr>
                <tr>                    
                    <th scope="col" v-for="(col, index) in table.columns_names" :key="index">{{ col.label }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, rowIndex) in table.matrix" :key="rowIndex">
                    <td v-for="(col, colIndex) in table.columns_names" :key="colIndex">
                    {{ row[col.value] }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'TableComponent',
    props: {
        captionTitle: {
            type: String,
            required: true,
            default: ''
        },
        table: {
            type: Object,
            required: true,
        }
    }
}
</script>

<style scoped lang="scss">
@import "../styles/components/tableComponent.scss";
</style>
